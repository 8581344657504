import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Container from "../components/layout/container"
import SEO from "../components/seo/seo"

const Policy = styled.div`
  margin: auto;

  ol {
    list-style: lower-latin;
  }
`

const H1 = styled.h1`
  font-size: 60px;
`

const H2 = styled.h2`
  font-size: 25px;
  margin: 3rem 0 0.5rem;
  color: initial;
`

const privacy = () => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy – CEO amp"
        description="This is our privacy policy. It covers ceoamp.com and its 
          associated subdomains."
      />
      <Container>
        <Policy>
          <H1>Privacy Policy</H1>
          <H2>1. Introduction</H2>
          <p>
            This is our privacy policy. It covers ceoamp.com and its associated
            subdomains. It tells you how we collect and process data received
            from you on our site. please read the following carefully to
            understand our views and practices regarding your personal data and
            how we will treat it.
          </p>
          <p>
            If you have any comments on this privacy policy, please email them
            to amp@raconteur.net
          </p>
          <H2>2. Who We Are</H2>
          <p>
            Here are the details that the Regulation (EU) 2016/679 of the
            European parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regards to the processing of
            personal data and on the free movement of such data, known as
            General Data protection Regulation (GDPR) says we have to give you
            as a ‘data controller’:
          </p>
          <ul>
            <li>
              Our site address is{" "}
              <a href="https://www.ceoamp.com/">https://www.ceoamp.com</a>
            </li>
            <li>Our company name is Raconteur Media Ltd.</li>
            <li>
              Our registered address is: Raconteur Media Ltd, 2nd Floor,
              portsoken House, 155-157 Minories, London, EC3N 1LJ
            </li>
          </ul>
          <H2>3. What we may collect</H2>
          <p>We may collect and process the following data about you:</p>
          <ul>
            <li>
              Information you put into forms or surveys on our site at any time
            </li>
            <li>A record of any correspondence between us</li>
            <li>Details of transactions you carry out through our site</li>
            <li>
              Details of your visits to our site and the resources you use
            </li>
            <li>
              Information about your computer (e.g. your Ip address, browser,
              operating system, etc.) for system administration and to report
              aggregate information to our advertisers
            </li>
          </ul>
          <p>
            Under GDPR we will ensure that your personal data is processed
            lawfully, fairly, and transparently, without adversely affecting
            your rights. We will only process your personal data if at least one
            of the following basis applies:
          </p>
          <ol>
            <li>
              you have given consent to the processing of your personal data for
              one or more specific purposes;
            </li>
            <li>
              processing is necessary for the performance of a contract to which
              you are a party or in order to take steps at the request of you
              prior to entering into a contract;
            </li>
            <li>
              processing is necessary for compliance with a legal obligation to
              which we are subject;
            </li>
            <li>
              processing is necessary to protect the vital interests of you or
              of another natural person;
            </li>
            <li>
              processing is necessary for the performance of a task carried out
              in the public interest or in the exercise of official authority
              vested in the controller; and/or
            </li>
            <li>
              processing is necessary for the purposes of the legitimate
              interests pursued by us or by a third party such as our credit
              card payment processing, except where such interests are
              overridden by the fundamental rights and freedoms of the data
              subject which require protection of personal data, in particular
              where the data subject is a child.
            </li>
          </ol>
          <H2>4. Cookies</H2>
          <p>
            We use cookies to distinguish users and improve our site. Please
            look at our <Link to="/cookie-policy/">Cookie policy</Link> for more
            cookie information.
          </p>
          <H2>5. How we use what we collect</H2>
          <p>We use information about you to:</p>
          <ul>
            <li>present site content effectively to you.</li>
            <li>
              provide information, products and services that you request, or
              (with your consent) which we think may interest you.
            </li>
            <li>Carry out our contracts with you.</li>
            <li>Allow you to use our interactive services if you want to.</li>
            <li>
              Tell you about other goods and services that might interest you.
              We will also let other people do this, and we (or they) may
              contact you.
            </li>
          </ul>
          <p>
            If you are already our customer, we will only contact you
            electronically about things similar to what was previously sold to
            you.
          </p>
          <p>
            If you are a new customer, you will only be contacted if you agree
            to it.
          </p>
          <p>
            please note: We don’t identify individuals to our advertisers, but
            we do give them aggregate information to help them reach their
            target audience, and we may use information we have collected to
            display advertisements to that audience.
          </p>
          <p>
            In addition, if you don’t want us to use your personal data for any
            of the other reasons set out in this section in 5, you can let us
            know at any time by contacting us at amp@raconteur.net and we will
            delete your data from our systems. However, you acknowledge this
            will limit our ability to provide the best possible [products and]
            services to you.
          </p>
          <p>
            In some cases, the collection of personal data may be a statutory or
            contractual requirement, and we will be limited in the [products
            and] services we can provide you if you don’t provide your personal
            data in these cases.
          </p>
          <H2>6. Where we store your data</H2>
          <p>
            We may transfer your collected data to storage outside the European
            Economic Area (EEA). It may be processed outside the EEA to fulfil
            your order and deal with payment.
          </p>
          <p>
            By giving us your personal data, you agree to this arrangement. We
            will do what we reasonably can to keep your data secure.
          </p>
          <p>
            payment will be encrypted. If we give you a password, you must keep
            it confidential. please don’t share it. Although we try to provide
            protection, we cannot guarantee complete security for your data, and
            you take the risk that any sending of that data turns out to be not
            secure despite our efforts.
          </p>
          <p>
            We only keep your personal data for as long as we need to in order
            to use it as described above in section 5, and/or for as long as we
            have your permission to keep it. In any event, we will conduct an
            [annual] review to ascertain whether we need to keep your personal
            data. Your personal data will be deleted if we no longer need it.
          </p>
          <H2>7. Disclosing your information</H2>
          <p>
            We are allowed to disclose your information in the following cases:
          </p>
          <ul>
            <li>
              If we want to sell our business, or our company, we can disclose
              it to the potential buyer.
            </li>
            <li>We can disclose it to other businesses in our group.</li>
            <li>
              We can disclose it if we have a legal obligation to do so, or in
              order to protect other people’s property, safety or rights.
            </li>
            <li>
              We can exchange information with others to protect against fraud
              or credit risks.
            </li>
          </ul>
          <p>
            We may contract with third parties to supply services to you on our
            behalf. These may include payment processing, search engine
            facilities, advertising and marketing. In some cases, the third
            parties may require access to some or all of your data. These are
            the third parties that have access to your information:
          </p>
          <ul>
            <li>Google</li>
            {/* <li>Facebook</li>
            <li>LinkedIn</li> */}
          </ul>
          <p>
            Where any of your data is required for such a purpose, we will take
            all reasonable steps to ensure that your data will be handled
            safely, securely, and in accordance with your rights, our
            obligations, and the obligations of the third party under GDPR and
            the law.
          </p>
          <H2>8. Your rights</H2>
          <p>
            You can ask us not to use your data for marketing. You can do this
            by ticking the relevant boxes on our forms, or by contacting us at
            any time at amp@raconteur.net
          </p>
          <p>Under the GDPR, you have the right to:</p>
          <ul>
            <li>
              request access to, deletion of or correction of, your personal
              data held by us at no cost to you;
            </li>
            <li>
              request that your personal data be transferred to another person
              (data portability);
            </li>
            <li>be informed of what data processing is taking place;</li>
            <li>restrict processing;</li>
            <li>to object to processing of your personal data; and</li>
            <li>complain to a supervisory authority.</li>
          </ul>
          <p>
            You also have rights with respect to automated decision-making and
            profiling as set out in section 11 below.
          </p>
          <p>
            To enforce any of the foregoing rights or if you have any other
            questions about our site or this privacy policy, please contact us
            at amp@raconteur.net
          </p>
          <H2>9. Links to other sites</H2>
          <p>
            please note that our terms and conditions and our policies will not
            apply to other websites that you get to via a link from our site. We
            have no control over how your data is collected, stored or used by
            other websites and we advise you to check the privacy policies of
            any such websites before providing any data to them.
          </p>
          <H2>10. Changes</H2>
          <p>
            If we change our privacy policy, we will post the changes on this
            page. If we decide to, we may also email you.
          </p>
          <H2>11. Automated Decision-Making and profiling</H2>
          <p>
            11.1 In the event that we use personal data for the purposes of
            automated decision-making and those decisions have a legal (or
            similarly significant effect) on you, you have the right to
            challenge to such decisions under GDPR, requesting human
            intervention, expressing their own point of view, and obtaining an
            explanation of the decision from us.
          </p>
          <p>
            11.2 The right described in section 11.1 does not apply in the
            following circumstances:
          </p>
          <ol>
            <li>
              the decision is necessary for the entry into, or performance of, a
              contract between the you and us;
            </li>
            <li>the decision is authorised by law; or</li>
            <li>you have given you explicit consent.</li>
          </ol>
          <p>
            11.3 Where we use your personal data for profiling purposes, the
            following shall apply:
          </p>
          <ol>
            <li>
              Clear information explaining the profiling will be provided,
              including its significance and the likely consequences;
            </li>
            <li>
              Appropriate mathematical or statistical procedures will be used;
            </li>
            <li>
              Technical and organisational measures necessary to minimise the
              risk of errors and to enable such errors to be easily corrected
              shall be implemented; and
            </li>
            <li>
              All personal data processed for profiling purposes shall be
              secured in order to prevent discriminatory effects arising out of
              profiling.
            </li>
          </ol>
          <H2>12. Dispute Resolution</H2>
          <p>
            12.1 The parties will use their best efforts to negotiate in good
            faith and settle any dispute that may arise out of or relate to this
            privacy policy or any breach of it.
          </p>
          <p>
            12.2 If any such dispute cannot be settled amicably through ordinary
            negotiations between the parties, or either or both is or are
            unwilling to engage in this process, either party may propose to the
            other in writing that structured negotiations be entered into with
            the assistance of a fully accredited mediator before resorting to
            litigation.
          </p>
          <p>
            12.3 If the parties are unable to agree upon a mediator, or if the
            mediator agreed upon is unable or unwilling to act and an
            alternative mediator cannot be agreed, any party may within 14 days
            of the date of knowledge of either event apply to LawBite to appoint
            a mediator under the LawBite Mediation procedure.
          </p>
          <p>
            12.4 Within 14 days of the appointment of the mediator (either by
            mutual agreement of the parties or by LawBite in accordance with
            their mediation procedure), the parties will meet with the mediator
            to agree the procedure to be adopted for the mediation, unless
            otherwise agreed between the parties and the mediator.
          </p>
          <p>
            12.5 All negotiations connected with the relevant dispute(s) will be
            conducted in confidence and without prejudice to the rights of the
            parties in any further proceedings.
          </p>
          <p>
            12.6 If the parties agree on a resolution of the dispute at
            mediation, the agreement shall be reduced to writing and, once
            signed by the duly authorised representatives of both parties, shall
            be final and binding on them.
          </p>
          <p>
            12.7 If the parties fail to resolve the dispute(s) within 60 days
            (or such longer term as may be agreed between the parties) of the
            mediator being appointed, or if either party withdraws from the
            mediation procedure, then either party may exercise any right to
            seek a remedy through arbitration by an arbitrator to be appointed
            by LawBite under the Rules of the LawBite Arbitration Scheme.
          </p>
          <p>
            12.8 Any dispute shall not affect the parties’ ongoing obligations
            under this privacy policy.
          </p>
        </Policy>
      </Container>
    </Layout>
  )
}

export default privacy
